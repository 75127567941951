
import { Vue } from 'vue-class-component';

export default class HelloWorld extends Vue {
    private userName = '';
    private nameSaved = false;
    private quotes = [
        {
            quote: `{{username}}, a journey of a thousand miles, starts with a single step.`,
            author: 'Lao-Tzu'
        },{
            quote: `Believe you can, and you're halfway there {{username}}.`,
            author: 'Theodore Roosevelt'
        },{
            quote: 'Be the change you want to see in the world.',
            author: 'Mahatma Gandhi'
        },{
            quote: `{{username}}, wake up & be awesome!`,
            author: ''
        },{
            quote: `Everyday is a second chance {{username}}`,
            author: ''
        },{
            quote: 'Always believe in yourself!',
            author: ''
        },{
            quote: 'You are the artist of your own life. Don\'t hand the paintbrush to anyone else.',
            author: ''
        },{
            quote: `Nothing is impossible {{username}}. The word itself says I'm possible!`,
            author: 'Audrey Hepburn'
        },{
            quote: `The best view comes after the hardest climb {{username}}.`,
            author: ''
        },{
            quote: 'You are never too old to set another goal or to dream a new dream.',
            author: 'C.S. Lewis'
        },{
            quote: `{{username}}: when you get tired, learn to rest, not quit.`,
            author: 'Banksy'
        },{
            quote: `Push yourself {{username}}, noone else is going to do it for you.`,
            author: ''
        },{
            quote: 'Success is not an activity, but a process.',
            author: ''
        },{
            quote: `{{username}}, it's going to be hard, but hard does not mean impossible.`,
            author: ''
        },{
            quote: 'Success comes to those who act.',
            author: ''
        },{
            quote: 'Discipline is the bridge between goals and accomplishment.',
            author: 'Jim Rohn'
        },{
            quote: `{{username}}, ask yourself if what you are doing today is getting you closer to where you want to be.`,
            author: ''
        }];


    private get isUserKnown(): boolean {
        return this.userName !== '' && this.nameSaved;
    }

    private saveName() {
        if (this.userName === '') {
            return;
        }

        localStorage.setItem('username', this.userName);
        this.nameSaved = true;
    }

    public created() {
        this.userName = localStorage.getItem('username') ?? '';
        if (this.userName !== '') {
            this.nameSaved = true;
        }
    }

    private get inspiration(): any {
        const ix = Math.floor(Math.random() * this.quotes.length);
        return this.quotes[ix].quote.replace('{{username}}', this.userName);
    }
}
