import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0b575cf4")
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick", "onBlur", "onUpdate:modelValue", "id"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "panel",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit($event, -1)))
  }, [
    _createVNode(_component_ion_icon, {
      onClick: _ctx.addItem,
      name: "add-circle-outline"
    }, null, 8, ["onClick"]),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "todo",
          key: index
        }, [
          _createElementVNode("input", {
            type: "checkbox",
            name: "task",
            onClick: ($event: any) => (_ctx.toggleItem(item))
          }, null, 8, _hoisted_1),
          (_ctx.currentEditIx !== index)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["title", { done : item.Done }]),
                onClick: ($event: any) => (_ctx.edit($event, index))
              }, _toDisplayString(item.Title), 11, _hoisted_2))
            : _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                class: "title",
                onClick: ($event: any) => (_ctx.edit($event, index)),
                onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addItem && _ctx.addItem(...args)), ["enter"])),
                onBlur: ($event: any) => (_ctx.onBlur(item)),
                "onUpdate:modelValue": ($event: any) => (item.Title = $event),
                id: `todo-${index}`
              }, null, 40, _hoisted_3)), [
                [_vModelText, item.Title]
              ]),
          _createVNode(_component_ion_icon, {
            class: "delete",
            onClick: ($event: any) => (_ctx.deleteTodo(item)),
            name: "close-circle-outline"
          }, null, 8, ["onClick"])
        ]))
      }), 128))
    ])
  ]))
}