
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import SimpleTodo from '@/components/SimpleTodo.vue';
import Pomodoro from '@/components/Pomodoro.vue';
import Note from '@/components/Note.vue';

import Axios from 'axios';
import { DateHelper } from '@/utils/DateHelper';

@Options({
    components: {
        HelloWorld,
        SimpleTodo,
        Pomodoro,
        Note
    },
})
export default class App extends Vue {
    private images: Image[] = [];
    private backgroundImage = {};
    private hiddenWidgets:string[] = [];
    private showFeedback = false;

public created() {
        this.getImageData();
        this.getWidgetVisibility();
    }

    private isWidgetVisible(name: string): boolean {
        return this.hiddenWidgets.findIndex(w => w === name) === -1;
    }

    private toggleWidget(name: string) {
        const ix = this.hiddenWidgets.findIndex(w => w === name);
        ix === -1 ? this.hiddenWidgets.push(name) : this.hiddenWidgets.splice(ix, 1);
        localStorage.setItem('hiddenwidgets', JSON.stringify(this.hiddenWidgets));
    }

    private getName(widget: string): string {
        let name = 'help-circle';
        switch (widget) {
            case 'pomodoro':
                name = 'timer';
                break;
            case 'todo':
                name = 'checkmark-circle';
                break;
            case 'note':
                name = 'note';
                break;
            case 'helloworld':
                name = 'heart';
                break;
        }
        if (!this.isWidgetVisible(widget)) {
            name += '-outline';
        }
        return name;
    }

    private getWidgetVisibility() {
        const hiddenWidgets = localStorage.getItem('hiddenwidgets');
        if (hiddenWidgets) {
            this.hiddenWidgets = JSON.parse(hiddenWidgets);
        }
    }

    private getImageData() {
        Axios.get('/bg/data.json').then((res) => {
            let file = res.data; 
            this.images = file.images as Image[];
            this.setImage();
        });
    }

    private setImage() {
        let bgimage = localStorage.getItem('bgimage');
        const today = new Date().toISOString().split('T')[0];
        if (bgimage === null) {
            const ix = Math.floor(Math.random() * this.images.length);
            bgimage = JSON.stringify({ ix: ix, date: today });
            localStorage.setItem('bgimage', bgimage);
        }
        const bgInfo = JSON.parse(bgimage);
        if (!DateHelper.isSameDay(new Date(Date.parse(bgInfo.date)), new Date())) {
            bgInfo.ix++;
            if (bgInfo.ix === this.images.length) {
                bgInfo.ix = 0;
            }
            bgInfo.date = today;
            localStorage.setItem('bgimage', JSON.stringify(bgInfo));
        }
        this.backgroundImage = this.images[bgInfo.ix];
    }
}

class Image {
    public Artist = '';
    public Contact = '';
    public Imageid = '';
}
