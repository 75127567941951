
import { Vue } from 'vue-class-component';

export default class HelloWorld extends Vue {
    private note = 'asdf';
    private editMode = false;

    public created() {
        this.note = localStorage.getItem('note-text') ?? '';
        const fEditMode = localStorage.getItem('note-editMode');
        this.editMode = (!fEditMode || fEditMode.toLowerCase() === 'true');
    }

    private save() {
        localStorage.setItem('note-text', this.note);
    }

    private toggleEditMode() {
        this.editMode = !this.editMode;
        localStorage.setItem('note-editMode', this.editMode.toString());
    }
}
