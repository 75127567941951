
import { Vue } from 'vue-class-component';
import { v4 as uuidv4 } from 'uuid';

class ToDo {
    public Id = '';
    public Title = '';
    public Text = '';
    public Done = false;
    public EntryDate = new Date();
    public EndDate:Date = new Date();
}

export default class SimpleTodo extends Vue {
    private items:ToDo[] = [];
    private currentEditIx = -1;

    public created() {
        // get from localstorage
        const items = localStorage.getItem('todos');
        if (!items) {
            return;
        }

        this.items = JSON.parse(items);
        this.cleanup();
    }

    private cleanup() {
        for (let i = this.items.length - 1; i >= 0; i--) {
            if (this.items[i]?.Title.trim().length === 0 || this.items[i]?.Done) {
                this.items.splice(i, 1);
            }     
        }
        this.save();
    }

    private toggleItem(item: ToDo) {
        item.Done = !item.Done;
        item.EndDate = new Date();
        this.save();
    }

    private onBlur(item: ToDo) {
        this.deleteIfEmpty(item);
        this.save();
    }

    private deleteTodo(item: ToDo) {
        const ix = this.items.findIndex(todo => todo.Id === item.Id);
        this.items.splice(ix, 1);
    }

    private deleteIfEmpty(item: ToDo) {
        if (item.Title.trim().length === 0) {
            this.deleteTodo(item);
        }
    }

    private save() {
        localStorage.setItem('todos', JSON.stringify(this.items));
    }

    private addItem($event: PointerEvent) {
        $event.stopImmediatePropagation();
        const newItem = new ToDo();
        newItem.Id = uuidv4();
        this.items.push(newItem);
        this.currentEditIx = this.items.length - 1;
        this.$nextTick(() => {
            const item = `todo-${this.currentEditIx}`;
            document.getElementById(item)!.focus();
        });
        this.save();
    }

    private edit($event: MouseEvent, index: number) {
        $event.stopImmediatePropagation();
        this.currentEditIx = index;
        this.save();
    }
}

