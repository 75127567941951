export abstract class DateHelper {
    public static isSameDay(pDate: Date, pDate2: Date): boolean {
        return pDate.getFullYear() === pDate2.getFullYear() && pDate.getMonth() === pDate2.getMonth() && pDate.getDate() === pDate2.getDate();
    }

    public static addDays(pDate: Date, pDays: number): Date {
        const fRes = new Date(pDate); // because: https://stackoverflow.com/a/19691491
        fRes.setDate(fRes.getDate() + pDays);
        return fRes;
    }

    public static getDateNumber(pDate?: Date): number {
        if (!pDate) {
            return 0;
        }

        const fDate = new Date(pDate);
        return fDate.getFullYear() * 10000 + fDate.getMonth() * 100 + fDate.getDate();
    }

    /** Get the number of days since initialization.
     * This can be used to compare dates.
     */
    public static getDays(pDate?: Date): number {
        if (!pDate) {
            return 0;
        }

        const fDate = new Date(pDate);
        return Date.UTC(fDate.getFullYear(), fDate.getMonth(), fDate.getDate()) / (1000 * 60 * 60 * 24);
    }

    /** Check whether two dates are equal, based on the number of days. */
    public static datesAreEqual(pDate?: Date, pCompareDate?: Date): boolean {
        return this.getDays(pDate) === this.getDays(pCompareDate);
    }

    /** Check whether the date is before the compared date. */
    public static dateIsBefore(pDate?: Date, pCompareDate?: Date): boolean {
        return this.getDays(pDate) <= this.getDays(pCompareDate);
    }

    /** Check whether the date is after the compared date. */
    public static dateIsAfter(pDate?: Date, pCompareDate?: Date): boolean {
        return this.getDays(pDate) >= this.getDays(pCompareDate);
    }

    public static dateInRange(pDate?: Date, pStartDate?: Date, pEndDate?: Date): boolean {
        const fDateNumer = this.getDateNumber(pDate);
        return this.getDateNumber(pStartDate) <= fDateNumer && this.getDateNumber(pEndDate) >= fDateNumer;
    }
}
